/*
 * This file is part of Testfish Runner.
 * (c) Basis Europe <eu@basis.com>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getNextQuestion, answerQuestion } from '../../modules/testrunner'
import { toast, Flip } from 'react-toastify'
import Timer from 'react-compound-timer'
import { Line } from 'rc-progress'
import Spinner from '../spinner'
import { withTranslation } from 'react-i18next'
import { Formik, FieldArray } from 'formik'

toast.configure({
  autoClose: 10000,
  position: toast.POSITION.TOP_RIGHT,
  transition: Flip,
  draggable: true,
  draggablePercent: 60
})

class Questions extends React.Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onValidate = this.onValidate.bind(this)
    this.onGetNextQuestion = this.onGetNextQuestion.bind(this)
    this.onSelectionInputKeyUp = this.onSelectionInputKeyUp.bind(this)
    this.onTimedOut = this.onTimedOut.bind(this)
    this.clockRef = React.createRef()
    this.state = {
      answered: false,
      firstLoad: true
    }
  }

  componentDidMount() {
    this._mounted = true
    window.onbeforeunload = e => {
      return this.props.t('question.messages.reload')
    }

    this.props.getNextQuestion().then(
      () => {
        this.setState({ firstLoad: false })
      },
      () => {
        this.setState({ firstLoad: true })
      }
    )
  }

  componentWillUnmount() {
    this._mounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    // only reset clock if the question is changed
    if (prevProps.question.id !== this.props.question.id) {
      this.clockRef.current.reset()
    }
  }

  onSubmit(values, { setSubmitting, resetForm }) {
    // run on the next tick
    window.setTimeout(() => {
      const question = this.props.question.id
      const answers = (Array.isArray(values.answers)
        ? values.answers
        : [values.answers]
      ).map(i => String(i))
      setSubmitting(true)
      this.props
        .answerQuestion(question, answers)
        .then(() => {
          if (this._mounted) {
            this.setState({ answered: true })
            this.clockRef.current.stop()
            setSubmitting(false)
          }
          toast.dismiss()
          toast.info(this.props.t('question.messages.saved'))
        })
        .catch(() => {
          this.setState({ answered: false })
          setSubmitting(false)
          toast.error(this.props.t('question.messages.failed'))
        })
    }, 0)
  }

  onValidate(values) {
    const errors = {}
    if (
      !values.answers ||
      ((Array.isArray(values.answers) && !values.answers.length) ||
        !values.answers.length)
    ) {
      errors.answers = 'No Answer is selected' // we dont show this message
    }

    return errors
  }

  onGetNextQuestion() {
    toast.dismiss()
    this.props.getNextQuestion().then(() => {
      this.setState({ answered: false }, () => {
        this.clockRef.current.start()
        window.scroll(0, 0)
      })
    })
  }

  onTimedOut(e) {
    this.setState({
      answered: true // means I don't know answer
    })

    this.clockRef.current.stop()
    toast.warning(this.props.t('question.messages.timedOut'))
  }

  onSelectionInputKeyUp(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      const target = e.target
      const input = target.querySelector('input')
      input.click()
    }
  }

  render() {
    const { t, question, loading } = this.props
    const { answered, firstLoad } = this.state
    const isLoading = !firstLoad && loading
    return (
      <div className="page">
        {firstLoad && <Spinner text={t('question.loading')} />}
        {isLoading && <Spinner opacity />}
        <div className="question">
          <div className="question__header">
            <div className="question__header__headline">
              {question.headline}
            </div>
            <div className="question__header__remaining">
              {question.questioncount - question.questionno}{' '}
              {t('question.remaining')}
            </div>
          </div>
          <Line
            percent={Math.floor(
              (question.questionno / question.questioncount) * 100
            )}
            strokeWidth="1"
            strokeColor="#ffa300"
          />

          <div
            className={
              answered
                ? 'question__content question__content--answered'
                : 'question__content'
            }>
            <div className="question__text">
              <div>
                <div className="question__clock">
                  <h2>{t('question.question')}</h2>
                  {question.time && (
                    <Timer
                      initialTime={question.time * 1000}
                      direction="backward"
                      ref={this.clockRef}
                      checkpoints={[
                        {
                          time: 0,
                          callback: this.onTimedOut
                        }
                      ]}>
                      {() => (
                        <div className="question__clock__time">
                          <span className="question__clock__minutes">
                            <Timer.Minutes /> {t('question.mins')}
                          </span>
                          <span className="question__clock__seconds">
                            <Timer.Seconds /> {t('question.secs')}
                          </span>
                        </div>
                      )}
                    </Timer>
                  )}
                </div>
                <div dangerouslySetInnerHTML={{ __html: question.text }}></div>
              </div>
            </div>
            <h3>{t('question.answers')}</h3>
            {question.answers && (
              <Formik
                key={question.id}
                initialValues={{
                  answers: []
                }}
                onSubmit={this.onSubmit}
                validate={this.onValidate}
                validateOnChange
                validateOnBlur>
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid = false,
                  touched,
                  errors,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FieldArray
                      validateOnChange
                      name="answers"
                      render={arrayHelpers =>
                        question.answers.map(answer => (
                          <label
                            key={question.id + answer.id}
                            className="selectionInput"
                            tabIndex="0"
                            onKeyUp={this.onSelectionInputKeyUp}>
                            <span
                              className="selectionInput__label"
                              dangerouslySetInnerHTML={{
                                __html:
                                  answer.text === 'common.dontKnow'
                                    ? t('common.dontKnow')
                                    : answer.text
                              }}
                              tabIndex="-1"></span>
                            <input
                              className="selectionInput__input"
                              id={answer.id}
                              name="answers"
                              type={
                                question.type === '1n' ? 'radio' : 'checkbox'
                              }
                              checked={values.answers.includes(answer.id)}
                              disabled={answered}
                              readOnly={answered}
                              onChange={e => {
                                if (e.target.checked) {
                                  if (
                                    answer.id === -1 ||
                                    question.type === '1n'
                                  ) {
                                    values.answers = []
                                  } else {
                                    const idx = values.answers.indexOf(-1)
                                    if (idx > -1) arrayHelpers.remove(idx)
                                  }

                                  arrayHelpers.push(answer.id)
                                } else {
                                  const idx = values.answers.indexOf(answer.id)
                                  arrayHelpers.remove(idx)
                                }
                              }}
                              onBlur={handleBlur}
                              tabIndex="-1"
                            />

                            <span
                              tabIndex="-1"
                              className={
                                question.type === '1n'
                                  ? 'selectionInput__checkmark selectionInput__checkmark--radio'
                                  : 'selectionInput__checkmark selectionInput__checkmark--checkbox'
                              }></span>
                          </label>
                        ))
                      }
                    />
                    {!answered && (
                      <button
                        className="button"
                        type="submit"
                        disabled={isSubmitting || !isValid}>
                        {isSubmitting
                          ? isValid
                            ? t('question.button.submitting')
                            : t('question.button.disabled')
                          : isValid
                          ? t('question.button.submit')
                          : t('question.button.disabled')}
                      </button>
                    )}
                  </form>
                )}
              </Formik>
            )}
          </div>
          {answered && (
            <button className="button" onClick={this.onGetNextQuestion}>
              {t('question.button.next')}
            </button>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ testrunner }) => ({
  loading: testrunner.loading,
  question: testrunner.question,
  finished: testrunner.finished
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNextQuestion,
      answerQuestion
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Questions))
