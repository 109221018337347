import React from 'react'
import CandidateForm from './candidateForm'
import { withTranslation } from 'react-i18next'

class Finished extends React.Component {
  render() {
    return (
      <div className="page">
        <p
          className="page__content"
          dangerouslySetInnerHTML={{
            __html: this.props.data.finishedtext
          }}></p>
        {this.props.data.collectcandidateinfo && <CandidateForm></CandidateForm>}
      </div>
    )
  }
}

export default withTranslation()(Finished)