/*
 * This file is part of Testfish Runner.
 * (c) Basis Europe <eu@basis.com>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import { getParam } from '../utils'
import API from '../api'

export const CREATE_PUP = 'CREATE_PUP'
export const CREATE_PUP_SUCCESS = 'CREATE_PUP_SUCCESS'
export const CREATE_PUP_ERROR = 'CREATE_PUP_ERROR'

export const INIT_TEST = 'INIT_TEST'
export const INIT_TEST_SUCCESS = 'INIT_TEST_SUCCESS'
export const INIT_TEST_ERROR = 'INIT_TEST_ERROR'

export const GET_QUESTION = 'GET_QUESTION'
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS'
export const GET_QUESTION_FINISHED = 'GET_QUESTION_FINISHED'
export const GET_QUESTION_ERROR = 'GET_QUESTION_ERROR'

export const SAVE_ANSWER = 'SAVE_ANSWER'
export const SAVE_ANSWER_SUCCESS = 'SAVE_ANSWER_SUCCESS'
export const SAVE_ANSWER_ERROR = 'SAVE_ANSWER_ERROR'

export const COLLECT_CANDIDATE_INFO = 'COLLECT_CANDIDATE_INFO'
export const COLLECT_CANDIDATE_INFO_SUCCESS = 'COLLECT_CANDIDATE_INFO_SUCCESS'
export const COLLECT_CANDIDATE_INFO_ERROR = 'COLLECT_CANDIDATE_INFO_ERROR'

export const createTestFromPub = (hash = getParam('hash')) => {
  return dispatch => {
    dispatch({ type: CREATE_PUP })

    return API.get(`/pub/${hash}`)
      .then(response => {
        const trun = response.data.trun
        dispatch({
          type: CREATE_PUP_SUCCESS,
          payload: trun
        })

        return Promise.resolve(trun)
      })
      .catch(error => {
        dispatch({
          type: CREATE_PUP_ERROR,
          payload: error
        })

        return Promise.reject(error)
      })
  }
}

export const initTest = (trun = getParam('trun')) => {
  return (dispatch, getState) => {
    trun = trun || getState().testrunner.trun

    if(trun.length === 0) {
      // a fallback for the old url pattern 
      const url = window.location.hash.split("#")
      if(url.length >= 2 && /^\/r\/[a-zA-Z0-9]{45,}$/.test(url[1])) {
        trun = url[1].split("/")[2]
        console.warn("using deprecated url schema to init the test runner")
      }
    }

    dispatch({ type: GET_QUESTION })

    return API.get(`/init/${trun}`)
      .then(response => {
        const data = response.data[0]

        dispatch({
          type: INIT_TEST_SUCCESS,
          payload: { data, trun }
        })

        return Promise.resolve({ data, trun })
      })
      .catch(error => {
        dispatch({
          type: INIT_TEST_ERROR,
          payload: error
        })

        return Promise.reject(error)
      })
  }
}

export const getNextQuestion = trun => {
  return (dispatch, getState) => {
    trun = trun || getState().testrunner.trun

    dispatch({ type: INIT_TEST })

    return API.post(`/question/${trun}`)
      .then(response => {
        const question = response.data[0]

        if (Object.keys(question).length) {
          if(question.dontknow) {
            question.answers.push({
              id: -1,
              idQUESTION: question.id,
              text: 'common.dontKnow'
            })
          }
          dispatch({
            type: GET_QUESTION_SUCCESS,
            payload: question
          })
        } else {
          dispatch({
            type: GET_QUESTION_FINISHED,
            payload: question
          })
        }

        return Promise.resolve(question)
      })
      .catch(error => {
        dispatch({
          type: GET_QUESTION_ERROR,
          payload: error
        })

        return Promise.reject(error)
      })
  }
}

export const answerQuestion = (question, answers, trun) => {
  return (dispatch, getState) => {
    trun = trun || getState().testrunner.trun
    const questionInState = getState().testrunner.question

    dispatch({ type: SAVE_ANSWER })

    return API.post(`/question/${trun}`, {
      question,
      answers
    })
      .then(response => {
        dispatch({
          type: SAVE_ANSWER_SUCCESS
        })

        if (questionInState.questioncount === questionInState.questionno) {
          dispatch({
            type: GET_QUESTION_FINISHED
          })
        }

        return Promise.resolve()
      })
      .catch(error => {
        dispatch({
          type: SAVE_ANSWER_ERROR,
          payload: error
        })

        return Promise.reject(error)
      })
  }
}

export const collectCandidateInfo = (trun, data) => {
  return (dispatch, getState) => {
    trun = trun || getState().testrunner.trun

    dispatch({ type: COLLECT_CANDIDATE_INFO })

    return API.post(`/trun/${trun}`, data)
      .then(response => {
        dispatch({
          type: COLLECT_CANDIDATE_INFO_SUCCESS
        })

        return Promise.resolve()
      })
      .catch(error => {
        dispatch({
          type: COLLECT_CANDIDATE_INFO_ERROR,
          payload: error
        })

        return Promise.reject(error)
      })
  }
}

const initialState = {
  data: {},
  trun: '',
  question: {},
  loading: false,
  finished: false,
  initialized: false,
  collected: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PUP:
      return {
        ...state,
        loading: true
      }
    case CREATE_PUP_SUCCESS:
      return {
        ...state,
        trun: action.payload,
        loading: false
      }

    case CREATE_PUP_ERROR:
      return {
        ...state,
        loading: false
      }

    case INIT_TEST:
      return {
        ...state,
        loading: true
      }

    case INIT_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        finished: false,
        initialized: true,
        collected: false,
        data: action.payload.data,
        trun: action.payload.trun
      }

    case INIT_TEST_ERROR:
      return {
        ...state,
        loading: false
      }

    case GET_QUESTION:
      return {
        ...state,
        loading: true
      }

    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        question: action.payload
      }

    case GET_QUESTION_FINISHED:
      return {
        ...state,
        loading: false,
        question: {},
        finished: true
      }

    case GET_QUESTION_ERROR:
      return {
        ...state,
        loading: false
      }

    case SAVE_ANSWER:
      return {
        ...state,
        loading: true
      }

    case SAVE_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case SAVE_ANSWER_ERROR:
      return {
        ...state,
        loading: false
      }
    case COLLECT_CANDIDATE_INFO:
      return {
        ...state,
        loading: true
      }
    case COLLECT_CANDIDATE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        collected: true,
      }
    case COLLECT_CANDIDATE_INFO_ERROR:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
