/*
 * This file is part of Testfish Runner.
 * (c) Basis Europe <eu@basis.com>
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Switch, View } from 'react-view-switch'
import Welcome from '../welcome'
import Questions from '../questions'
import Finished from '../finished'
import Spinner from '../spinner'
import { initTest } from '../../modules/testrunner'
import { withTranslation } from 'react-i18next';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      userStartedTest: false,
      triedInitialized: false,
      view: props.finished ? 'finished' : 'welcome'
    }

    this.onUserStartedTest = this.onUserStartedTest.bind(this)
  }

  componentDidMount() {
    if (!this.props.initialized || !this.state.triedInitialized) {
      this.props
        .initTest()
        .then(() => {
          const {data , i18n} =  this.props
          i18n.changeLanguage(data.lang)
          this.setState({ triedInitialized: true })
        })
        .catch(() => {
          this.setState({ triedInitialized: true })
          this.props.changePage()
        })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.finished) {
      return {
        view: 'finished'
      }
    }

    return null
  }

  onUserStartedTest(e) {
    this.setState((state, props) => {
      return {
        userStartedTest: true,
        view: props.finished ? 'finished' : 'questions'
      }
    })
  }

  render() {
    const {t} = this.props
    const triedInitialized = this.state.triedInitialized
    const initialized = this.props.initialized && triedInitialized
    return (
      <React.Fragment>
        {!triedInitialized && <Spinner text={t("home.loading")}/>}
        {initialized && (
          <Switch current={this.state.view}>
            <View viewId="welcome">
              <Welcome
                data={this.props.data}
                onUserStartedTest={this.onUserStartedTest}
              />
            </View>
            <View viewId="questions">
              <Questions />
            </View>
            <View viewId="finished">
              <Finished data={this.props.data} />
            </View>
          </Switch>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ testrunner }) => ({
  loading: testrunner.loading,
  data: testrunner.data,
  finished: testrunner.finished,
  initialized: testrunner.initialized
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      initTest,
      changePage: () => push('/404')
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Home))
